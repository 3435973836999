// Offpeak = 0; Shoulder = 1; Peak = 2

const stateMap = {
    SA: {
        name: "SA",
        offset: "Australia/Adelaide"
    },
    NSW: {
        name: "NSW",
        offset: "Australia/Sydney"
    },
    VIC: {
        name: "Vic",
        offset: "Australia/Melbourne"
    },
    QLD: {
        name: "Qld",
        offset: "Australia/Brisbane"
    },
    TAS: {
        name: "Tas",
        offset: "Australia/Hobart"
    }
}
const providerMap = {
    SA: {
        SAPN: {
            name: "SA Power Networks",
            url: "https://www.sapowernetworks.com.au/your-power/manage-your-power-use/your-meter-data/",
            time: 5,
            rates: {
                dem: {
                    days: [ 1, 1, 1, 1, 1, 1, 1 ],
                    start: 16,
                    end: 21,
                    avg: 30,
                    min: 1
                },
                tou: {
                    week: [ 2, 0, 0, 0, 0, 0, 2, 2, 2, 2, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2 ],
                    end:  [ 2, 0, 0, 0, 0, 0, 2, 2, 2, 2, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2 ],
                    peak: '6a-10a, 3p-1a',
                    off: '1a-6a',
                    shoulder: '10a-3p',
                }
            }
        }
    },
    VIC: {
        PC: {
            name: "Powercor",
            url: "https://www.powercor.com.au/for-your-home/manage-power-costs/energy-usage-information/",
            time: 10,
            rates: {
                dem: {
                    days: [ 1, 1, 1, 1, 1, 1, 1 ],
                    start: 15,
                    end: 21,
                    avg: 30,
                    min: 1
                },
                tou_split: {
                    week: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 0, 0, 0 ],
                    end:  [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 0, 0, 0 ],
                    peak: '4p-9p',
                    off: '9p-10a',
                    shoulder: '10a-4p',
                },
                tou: {
                    week: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0, 0 ],
                    end:  [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 0, 0 ],
                    peak: '3p-9p',
                    off: '9p-3p',
                    shoulder: 'N/A',
                },
            }
        }
    }
}

const state = () => ({
    states: stateMap,
    providers: providerMap,
})

export default {
    namespaced: true,
    state,
}