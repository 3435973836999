<template>
    <div>
        <div v-for="hr in 24" :key="hr" class="hr">
            <span>{{ hr - 1 }}</span>
            <i-button-group vertical>
                <i-button v-for="(type, idx) in ['O','S','P']" :key="type"
                    :active="range[hr - 1]===idx || range[hr - 1] == idx + 3"
                    :class="['type' + type, { fallback: range[hr - 1] > 2 }]"
                    @click="setRange(hr - 1,idx)">{{ type }}</i-button>
            </i-button-group>
        </div>
    </div>
</template>

<script>
export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            range: new Array(24)
        }
    },
    watch: {
        modelValue() {
            let lastRange = -1
            let range = new Array(24)
            for (let hr = 0; hr < 24; hr++) {
                if (0 <= this.modelValue[hr] && 3 > this.modelValue[hr]) {
                    lastRange = this.modelValue[hr] ?? 0
                    range[hr] = lastRange
                }
                else {
                    range[hr] = lastRange >= 0 && lastRange <= 2 ? lastRange + 3 : -1
                }
            }

            this.range = range
        },
    },
    methods: {
        setRange(hr, type) {
            if (this.range[hr] == type)
                this.range[hr] = -1
            else
                this.range[hr] = type

            this.$emit('update:modelValue', this.range)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@inkline/inkline/css/variables';
@import '@inkline/inkline/css/mixins';

@include i-button() {
    @include variant('dark') {
        &.typeP {
            ----background--active: var(--color--orange-75);
        }
        &.typeO {
            ----background--active: var(--color--blue-75);
        }
        &.typeS {
            ----background--active: var(--color--green-75);
        }
    }

    &.typeP {
        ----background--active: var(--color--orange-35);
    }
    &.typeO {
        ----background--active: var(--color--blue-35);
    }
    &.typeS {
        ----background--active: var(--color--green-35);
    }
}
</style>

<style scoped>
.hr {
    display:inline-block;
    text-align:center;
    margin: 0.5rem 0;
}

.hr * {
    display:block
}

</style>