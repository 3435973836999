import { createStore, createLogger } from 'vuex'
import user from './modules/user'
import prefs from './modules/prefs'
import providers from './modules/providers'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    user,
    prefs,
    providers
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})