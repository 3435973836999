const dayjs = require('dayjs')
dayjs.extend(require('dayjs/plugin/customParseFormat'))
dayjs.extend(require('dayjs/plugin/dayOfYear'))

const state = () => ({
    provider: '',
    state: '',
    tariff: '',
    data: { },
    // map
    // date => {tou: peak/off/shoulder}, {demand: max draw, total off, total}, { single: rate },
    loading: false,
    endDate: null,
    startDate: null,
    months: 0,
    days: 0
})

const getters = {
    provider: (state) => {
        return state.provider
    },
    state: (state) => {
        return state.state
    },
    data: (state) => {
        return state.data
    },
    loading: (state) => {
        return state.loading
    },
}

const actions = {
    setProvider({ commit, rootState, state }, provider) {
        commit('setProvider', provider)

        const data = rootState.providers.providers[state.state][provider]
        // @TODO: Summer
        commit('clearData')
        commit('setTariff', data['rates'])
    },
    setState({ commit }, state) {
        commit('setState', state)
        commit('setProvider', '')
    },

    setTariff ({ commit }, tariff) {
        commit('setTariff', tariff)
    },

    setNMIFile({ commit, state }, file) {
        const Papa = require('papaparse')
        let NMI = ''
        let regId = ''
        let interval = ''
        let data = {}
        let endDate = {}
        let startDate = {}
        commit('setLoading', true)
        Papa.parse(file, {
            worker: true,
            step: row => {
                const typeId = row.data[0]
                if (typeId == '200') {
                    NMI = row.data[1]
                    regId = row.data[3]
                    if (row.data[7] != 'KWH')
                        console.log('Unexpected unit type: ' + row.data[7])
                    interval = parseInt(row.data[8], 10)
                    if (interval != 30 && interval != 5)
                        console.log('Unexpected interval length: ' + interval) // 30m or some meters do 5-min intervals

                    data[NMI] = data[NMI] || {}
                    data[NMI][regId] = data[NMI][regId] || {}
                    endDate[NMI+'-'+regId] = endDate[NMI+'-'+regId] || dayjs('2000-01-01', 'YYYY-MM-DD')
                    startDate[NMI+'-'+regId] = startDate[NMI+'-'+regId] || dayjs('3000-01-01', 'YYYY-MM-DD')
                }
                else if (typeId == '300') {
                    const date = row.data[1]
                    const dataLen = 2 + 24 * (60 / interval)
                    const rowData = row.data.slice(2, dataLen) // 24*30m = 48 records
                    const qual = row.data[dataLen]
                    if (qual != "A") {
                        console.log('Unknown row quality type: ' + qual)
                        return
                    }

                    const tariff = state.tariff
                    let tou = new Array(48)
                    const dt = dayjs(date, 'YYYYMMDD')
                    if (dt > endDate[NMI+'-'+regId])
                        endDate[NMI+'-'+regId] = dt
                    if (dt < startDate[NMI+'-'+regId])
                        startDate[NMI+'-'+regId] = dt
                    for (let i = 0; i < rowData.length; ++i) {
                        const hr = Math.floor(i * interval / 30)
                        tou[hr] = (tou[hr] || 0.0) + parseFloat(rowData[i])
                    }

                    const avg = tariff['dem']['avg'];
                    let dem = tariff['dem']['min'];
                    for (let i = tariff['dem']['start'] * (60 / interval); (i + avg / 60 / interval) < (24 * 60 / interval); ++i) {
                        let sum = 0.0;
                        for (let j = 0; j < avg / interval; j++)
                            sum += parseFloat(rowData[j])

                        sum = sum * 60 / avg

                        if (dem < sum)
                            dem = sum;
                    }

                    data[NMI][regId][date] = { demand: dem, tou, d: dt }
                }
                else
                    console.log('Unknown row ID %s', typeId)
            },
            complete: () => {
                commit('setData', { data, startDate, endDate })
                commit('setLoading', false)
                console.log("Done")
            },
            error: err => {
                console.log('Error parsing data')
                commit('setLoading', false)
                console.log(err)
            }
        });
    },
}

const mutations = {
    setProvider(state,  val) {
        state.provider = val
    },
    setState(state,  val) {
        state.state = val
    },

    setTariff(state,  val) {
        state.tariff = val
    },

    setData(state, { data, startDate, endDate } ) {
        let end = dayjs('3000-01-01', 'YYYY-MM-DD')
        let start = dayjs('2000-01-01', 'YYYY-MM-DD')
        const keys = Object.keys(startDate)
        keys.forEach(dt => {
            if (startDate[dt] > start)
                start = startDate[dt]
            if (endDate[dt] < end)
                end = endDate[dt]
        })

        state.data = data
        state.startDate = start
        state.endDate = end
        state.months = Math.floor(((end-start)/1000/60/60/24/365*12)+.15) // Random padding
        state.days = Math.floor((end-start)/1000/60/60/24)
    },

    clearData(state) {
        state.data = {}
    },

    setLoading(state, isLoading) {
        state.loading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}