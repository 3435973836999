const state = () => ({
    noSave: false
})

const getters = {
    isNoSave: (state) => { return state.noSave }
}

const actions = {
    setNoSave({ commit }, val) {
        commit('setNoSave', val)
    }
}

const mutations = {
    setNoSave(state, val) {
        state.noSave = val
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}