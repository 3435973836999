<template>
  <span>
    <h1>Your Power Profile</h1>

    <div class="summary">

      <div class="summary-block">
        <h2>Single Rate</h2>
        <div>
          <h3>Total Import</h3>
          <span>{{ formatn(totalImport) }} kWh</span>
        </div>
        <div>
          <h3>Solar Exports</h3>
          <span>{{ formatn(totalExport) }} kWh</span>
        </div>
      </div>

      <div>
        <h2>Time of Use</h2>
        <div v-bind:title="tariff.tou.peak">
          <h3 @click="editToU()">Peak</h3>
          <span>{{ formatn(touPeak) }} kWh</span>
        </div>
        <div v-bind:title="tariff.tou.off">
          <h3>Offpeak</h3>
          <span>{{ formatn(touOffpeak) }} kWh</span>
        </div>
        <div v-bind:title="tariff.tou.shoulder">
          <h3>Shoulder</h3>
          <span>{{ formatn(touShoulder) }} kWh</span>
        </div>
        <div v-on:click="editToU()">Edit times</div>
      </div>

      <div>
        <h2>Demand Tariff</h2>
        <div>
          <h3>Peak demand</h3>
          <span>{{ formatn(demStats.max) }} kW ({{ demStats.maxMonthD }})</span>
        </div>
        <div>
          <h3>Minimum peak</h3>
          <span>{{ formatn(demStats.min) }} kW ({{ demStats.minMonthD }})</span>
        </div>
       <!-- <div>
          <h3>Average</h3>
          <span>{{ formatn(demAvg) }} kW</span>
        </div>-->
      </div>
    </div>

    <TimeOfUseModal v-model:touHours="touHours" v-model:isVisible="modalVisible"/>
  </span>
</template>

<style scoped>
.summary {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
}

.summary > div {
  margin: 0 1em 0 0;
}

.summary h2 {
  font-size: 1.5em;

}

.summary h3 {
  font-size: 1.2em;
  margin-top: 1.2em;
  margin-bottom: 0.2em;
}

.summary  div span {
  padding-left: 0.75em;
}
</style>

<script>
import { mapState } from 'vuex'
import TimeOfUseModal from './TimeOfUseModal.vue'

const dayjs = require('dayjs')
dayjs.extend(require('dayjs/plugin/customParseFormat'))
dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

export default {
  components: {
    TimeOfUseModal
  },
  data() {
    return {
      viewMonth: 12,
      totalImport: 0,
      touPeak: 0,
      touOffpeak: 0,
      touShoulder: 0,
      demAvg: 0,
      demStats: {},
      touHours: new Array(24),
      modalVisible: false,
    }
  },
  computed: {
    ...mapState('user', ['data', 'provider', 'tariff', 'state', 'endDate', 'months', 'days']),
    ...mapState('providers', ['states']),
    totalExport() {
      let totExp = 0.0
      this.dataForeach(function(data) {
        totExp += Object.values(data.tou).reduce((tot, curr) => tot + curr)
      }, false)
      return totExp
    },
    demand() {
      let ret = {}
      this.dataForeach(function (data) {
      let currBest = ret[data.d.format('YYMM')] || 0
      if (currBest < data.demand)
        ret[data.d.format('YYMM')] = data.demand
      }, false, this.months)
      return ret
    },
    peakHours() {
      return '';
    }
  },
  methods: {
    formatn(val, prec = 2) {
      if (typeof(val) == 'number')
        return val.toFixed(prec)
      return ''
    },
    editToU() {
      console.log('a')
      this.modalVisible = true
    }
  },
  watch: {
    data() { this.refilterData() },
    viewMonth() { this.refilterData() }
  },
  created: function() {
    this.dataForeach = function(callback, isImport = true, months = this.viewMonth) {
      const cutoff = this.endDate.subtract(months, 'month')

      Object.keys(this.data).forEach(nmi => {
        Object.keys(this.data[nmi])
          .filter(id => id.startsWith(isImport ? 'E' : 'B'))
          .forEach(id => {
            Object.keys(this.data[nmi][id])
              .filter(dt => dayjs(dt,'YYYYMMDD') > cutoff)
              .forEach(dt => {
                const day = this.data[nmi][id][dt]
                callback.bind(this)(day)
              })
          })
      })
    }

    this.refilterData = function() {
      let totImp = 0.0
      let peak = 0.0
      let offpeak = 0.0
      let shoulder = 0.0
      let avgDemand = 0.0
      let demand = {}
      let demandCnt = 0

      let toTz = this.states[this.state].offset
      this.dataForeach(function(data) {
        let day = data.d.dayOfYear()
        data.tou.forEach((val, hr) => {
          let tzAdjDate = dayjs.tz(data.d.format('YYYY-MM-DD ') + (Math.floor(hr/2.0)) + ':' + (hr % 2 == 0 ? '00' : '30') + ':00', 'Australia/Brisbane').tz(toTz)

          const touBlock = this.tariff['tou'][day == 0 || day == 6 ? 'end' : 'week'][tzAdjDate.hour()]
          if (touBlock == 0)
            offpeak += val
          else if (touBlock == 1)
            shoulder += val
          else
            peak += val

          totImp += val
        })

        avgDemand += data.demand
        ++demandCnt

        let currBest = demand[data.d.format('YYMM')] || 0
        if (currBest < data.demand)
          demand[data.d.format('YYMM')] = data.demand
      })

      let keys = Object.keys(demand)
      if (keys.length == 0)
        return {}
      let minIdx = keys[0]
      let maxIdx = keys[0]
      keys.forEach(v => {
        if (demand[maxIdx] < demand[v]) maxIdx = v
        if (demand[minIdx] > demand[v]) minIdx = v
      })

      this.totalImport = totImp
      this.touPeak = peak
      this.touShoulder = shoulder
      this.touOffpeak = offpeak
      this.demAvg = avgDemand / demandCnt
      this.demStats = {
          max: demand[maxIdx], maxMonth: maxIdx, maxMonthD: dayjs().day(1).month((parseInt(maxIdx) % 100)-1).year(2000 + Math.floor(parseInt(maxIdx) / 100)).format('MMM YYYY'),
          min: demand[minIdx], minMonth: minIdx, minMonthD: dayjs().day(1).month((parseInt(minIdx) % 100)-1).year(2000 + Math.floor(parseInt(minIdx) / 100)).format('MMM YYYY')}
    }

    this.refilterData()
  },
}
</script>