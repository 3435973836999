<template>
  <i-modal v-model="visible" id="modal">
      <div class="_clearfix">
        <h2>Set the ToU times</h2>
        <span>Adjust the hours for TOU if they are not correct for your provider</span>
        <TimeOfUseSelector v-model="range"/>
        <i-button @click="$emit('update:touHours', range); this.visible=false" class="_float:right">Ok</i-button>
      </div>
  </i-modal>
</template>

<script>
import TimeOfUseSelector from './TimeOfUseSelector.vue'

export default {
    components: {
        TimeOfUseSelector
    },
    props: ['touHours', 'isVisible'],
    emits: ['update:touHours', 'update:isVisible'],
    data() {
        return {
            range: this.touHours,
            visible: this.isVisible
        }
    },
    watch: {
        isVisible() {
            this.visible = this.isVisible
        },
        touHours() {
            let range = new Array(24)
            this.touHours.forEach((val, idx) => {
                if (idx < 24)
                    range[idx] = val > 2 || val < 0 ? val : 1
            })
            this.range = range
            console.log('set tou')
        },
        visible() {
            this.$emit('update:isVisible', this.visible)
        },
    }
}
</script>

<style scoped>

</style>