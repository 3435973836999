<template>
<span>
    <h1>⚡ Ready to get started?</h1>
    <p class="lead">Electricity plans are hard to understand at the best of times. Yearly plan changes, time of use, imports and exports - what is best for one person may be terrible for another, so how do you pick the best? </p>
    <p class="lead"> Using your prior meter data helps estimate costs for a given usage pattern. </p>
    <div>
        <i-checkbox class="_float:left _display:inline-block" :modelValue="isNoSave" @update:modelValue="setNoSave">I am on a public PC, do not save my data</i-checkbox>
        <i-button class="_float:right" color="primary" v-on:click="$emit('advance')">Yes, give me the power</i-button>
    </div>
</span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('prefs', ['isNoSave'])
    },
    methods: {
        ...mapActions('prefs', ['setNoSave'])
    },
    emits: {
        advance: null
    }
}
</script>