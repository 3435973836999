<template>
  <div>
    <transition name="step-fade" mode="out-in">
      <component :is="view" v-on:advance="nextStep()"></component>
    </transition>
  </div>
  <footer>
    <a @click="privacy = true" href="#">Privacy Policy</a>
    <PrivacyPolicy v-model="privacy" /> <CaffeineFund/>
  </footer>
</template>

<script>
import WelcomeMessage from './components/WelcomeMessage.vue'
import ImportNMI from './components/ImportNMI.vue'
import UploadData from './components/UploadData.vue'
import PlanSummary from './components/PlanSummary.vue'

import PrivacyPolicy from './components/PrivacyPolicy.vue'
import CaffeineFund from './components/CaffeineFund.vue'

export default {
  name: 'App',
  components: {
    WelcomeMessage,
    ImportNMI,
    UploadData,
    PlanSummary,

    PrivacyPolicy,
    CaffeineFund,
  },
  data() {
    return {
      timeout: null,
      privacy: false,
      stepDebounce: 0
    };
  },
  computed: {
    view: function() {
      switch (this.step) {
        default:
        case 0:
          return WelcomeMessage

        case 1:
          return ImportNMI

        case 2:
          return UploadData

        case 3:
          return PlanSummary
      }
    },
    step: {
      get() {
        return this.stepDebounce
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.stepDebounce = val
        }, 250)
      }
    }
  },

  methods: {
    nextStep() {
      this.step++
    },
  }
};
</script>

<style>
html, body, #app {
  height: 100%;
  margin: 0;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;*/
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

#app > div {
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

#app > div > * {
  display:inline-block;
  max-width: 40rem;
}

footer {
  flex-shrink: 0;
  text-align: center;
  padding: 1em;
}

footer > * {
  margin: 0em 1em
}

.step-fade-enter-active,
.step-fade-leave-active {
  transition: opacity 0.3s ease;
}

.step-fade-enter-from,
.step-fade-leave-to {
  opacity: 0;
}

</style>
