<template>
<span>
    <h1>Upload your data</h1>
    <p class="lead">Now your data can be uploaded into the browser.</p>
    <p>All data is stored and processed via the local browser.</p>
    <i-form>
        <i-form-group>
            <i-form-label>Select NMI data file</i-form-label>
        <i-input id="uploadCSV" type="file" accept="text/csv" @change="uploadFile()" />
        </i-form-group>
        <i-form-group>
            <i-button color="primary" class="_float:right" :loading="loading" :disabled="!canAdvance" :outline="!canAdvance" @click="$emit('advance')">View Profile
                <template #loading>
                    <i-loader color="dark" class="_margin-right:1/2" />
                    Processing...
                </template>
            </i-button>
            <a v-on:click="downloadCsv()" v-if="canAdvance" href="#">Download processed</a>
        </i-form-group>
    </i-form>
</span>
</template>

<style scoped>
.explain {
    min-height: 5em;
    padding-top: 1em;
}
</style>

<script>

import { mapState, mapActions } from 'vuex'

export default {
    setup() {

    },
    emits: ['advance'],
    data() {
        return  {
        }
    },
    computed: {
        canAdvance() { return !this.loading && Object.keys(this.data).length > 0 },
        ...mapState('user', [/*'state', 'provider',*/ 'data', 'loading'])

    },
    methods: {
        uploadFile() {
            this.setNMIFile(document.getElementById('uploadCSV').files[0])
        },
        ...mapActions('user', ['setNMIFile']),
        downloadCsv() {
        const a = document.createElement("a")
        let data = ['Meter,MeterElement,BlockStart (Brisbane TZ),kWh\n']
        Object.keys(this.data).forEach(nmi => {
            Object.keys(this.data[nmi]).forEach(meter => {
                Object.keys(this.data[nmi][meter]).forEach(time => {
                    const results = this.data[nmi][meter][time].tou
                    results.forEach((result, idx) => {
                        data.push(`${nmi},${meter},${time.substring(0,4)}-${time.substring(4,6)}-${time.substring(6,8)} ${Math.floor(idx/2)}:${idx%2==0?'00':'30'}:00,${result}\n`)
                    })
                })
            })
        })

        const file = new Blob(data, {type: 'text/csv'})
        a.href = URL.createObjectURL(file)
        a.download = 'processed.csv'
        a.click()
        }
    }
}
</script>