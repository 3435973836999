<template>
<span>
    <h1>Choose your distributor</h1>
    <p class="lead">First, we need your smartmeter data. Your distributor is able to provide this (usually via online registration).</p>
    <div>
        <i-button-group>
            <i-button v-for="(st,stateName) in states" v-bind:key="stateName" :active="state===st.name" @click="setState(stateName)">{{st.name}}</i-button>
        </i-button-group>
    </div>
    <div class="_margin-top:1">
        <i-button-group v-if="stateProviders"><!-- v-model="provider" v-on:click="emit"-->
            <i-button v-for="(item,name) in stateProviders" v-bind:key="name" :active="provider===name" @click="setProvider(name)">{{item.name}}</i-button>
        </i-button-group>
        <p v-if="!stateProviders && state" class="_font-italic">{{states[state].name}} is not yet supported</p>
    </div>
    <div class="explain">
        <span v-if="stateProviders && userProvider">
            Meter information from {{userProvider.name}} can be <a :href="userProvider.url">accessed online</a> and should take about {{userProvider.time}} minutes. A bill from your current provider will include the NMI number. When choosing to download data, select the 'Detailed Report' version.
        </span>
    </div>
    <i-button color="primary" class="_float:right" :disabled="!provider" :outline="!provider" @click="$emit('advance')">I have my meter data</i-button>
</span>
</template>

<style scoped>
.explain {
    min-height: 5em;
    padding-top: 1em;
}
</style>

<script>

import { mapState, mapActions } from 'vuex'

export default {
    setup() {

    },
    emits: ['advance'],
    data() {
        return  {

        }
    },
    computed: {
        stateProviders() { return this.providers[this.state] },
        userProvider() { return this.providers[this.state][this.provider] },
        ...mapState('providers', ['states' , 'providers']),
        ...mapState('user', ['state', 'provider'])
    },
    methods:
        mapActions('user', ['setProvider', 'setState'])

}
</script>