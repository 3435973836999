<template>
    <i-modal :value="value">
      <template v-slot:header>Privacy Policy</template>
      <p>
      We believe in a right to privacy. As such, all data entered to this site is stored only by your browser. Nothing reaches out servers. Accessing this site a second time will require you to re-upload your meter data to the browser.
      </p>
      <p>When accessing our site, your IP address and browser information may be recorded to help monitor for abuse against our servers.</p>
    </i-modal>
</template>

<script>
export default {
    props: ['value'],
    setup() {

    },
}
</script>